import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import AboutUsContent from "../components/InformationAboutUs/AboutUsContent"

const InformationAboutUs = () => {
  return (
    <Layout>
      <Navbar />
      <AboutUsContent />
      {/* <CaseStudiesDetailsContent /> */}
      <Footer />
    </Layout>
  )
}

export default InformationAboutUs
