import React from "react"
import aboutImage from "../../assets/images/about/about-img5.png"
import icon4 from "../../assets/images/icons/icon4.png"
import icon5 from "../../assets/images/icons/icon5.png"
import icon6 from "../../assets/images/icons/icon6.png"
import icon7 from "../../assets/images/icons/icon7.png"
import shape1 from "../../assets/images/shape/circle-shape1.png"

const AboutUsContent = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={aboutImage} alt="banner" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <h2>30 yıllık tecrübe Alışverişlio ile hayat buldu</h2>
                <p>
                  Erol Grup, bünyesindeki son girişimi Alışverişlio'yu Nisan
                  2021 yılında beta sürüm olarak, Eylül 2023 de ise son haline
                  getirerek hayata geçirdi.
                </p>

                <ul className="features-list">
                  <li>
                    <img src={icon4} alt="banner" />
                    <h3>31 Yıl</h3>
                    <p>Ticari tecrübe</p>
                  </li>
                  <li>
                    <img src={icon5} alt="banner" />
                    <h3>55.000</h3>
                    <p>Anlaşmalı nokta</p>
                  </li>
                  <li>
                    <img src={icon6} alt="banner" />
                    <h3>100%</h3>
                    <p>Yerli sermaye</p>
                  </li>
                  <li>
                    <img src={icon7} alt="banner" />
                    <h3>%98</h3>
                    <p>Mutlu kullanıcı</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3> Biz kimiz?</h3>
                <p>
                  1992 yılında ticaret hayatına başlamış 6 sektörde faaliyet
                  gösteren bir grup şirketiyiz.
                </p>

                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Yenilikçiyiz, amatör ruhla
                    profesyonel çalışırız
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Yerliyiz. Ülkemize fayda
                    üretmeyi esas alırız.
                  </li>

                  <li>
                    <i className="flaticon-tick"></i> Hep daha iyisi vardır
                    ,biliriz.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Çok çalışırız. İşimizi
                    aşkla yaparız.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Misyonumuz</h3>
                <p>
                  Alışverişlio olarak iyi bir seçeneğin daha olduğunu göstermek.
                </p>

                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Alternatif bir akıllı
                    ticaret modeli oluşturmak.
                  </li>

                  <li>
                    <i className="flaticon-tick"></i> Kobiler için alternatif
                    pazarlama altyapısı sunmak.
                  </li>

                  <li>
                    <i className="flaticon-tick"></i> Daha bilinçli, eğitimli
                    topluma katkı sağlamak.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="about-text">
                <h3>Vizyonumuz</h3>
                <p>
                  Türkiye'nin en çok tercih edilen, fayda üreten, alışveriş
                  uygulaması olmak.
                </p>

                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Türkiye'de bilinçli
                    tüketici hareketini başlatmak.
                  </li>

                  <li>
                    <i className="flaticon-tick"></i> 2025 sonrasinda global
                    pazara açılmak
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Ülkemizdeki küçük ve orta
                    ölçekli üreticilere yurtdışı pazarına açılabilecek alt
                    yapıyı sağlamak.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Kadın ve genç girişimciler
                    için özel destek programları uygulamak.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>
    </section>
  )
}

export default AboutUsContent
