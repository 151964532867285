import { Link } from "gatsby"
import React from "react"

import { navigate } from "@reach/router"
import { Button } from "react-bootstrap"
import { useRecoilState } from "recoil"
import logo from "../../assets/images/index/logo.png"
import { collapsedState } from "../../utils/recoil-atoms"

// import alisverislioLogo from "../../assets/images/index/logo.png"

const Navbar = props => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState)

  const onTap = props.onTap
  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    // let elementId = document.getElementById("navbar")
    // document.addEventListener("scroll", () => {
    //   if (window.scrollY > 170) {
    //     elementId.classList.add("is-sticky")
    //   } else {
    //     elementId.classList.remove("is-sticky")
    //   }
    // })
    // window.scrollTo(0, 0)
  })

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show"
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right"

  const buildAlisverislioNavBarItems = () => {
    // Alışverişlio Nedir? Markalar Üye İşyeri Ol Çarşı Blog SSS Hakkımızda Bize Ulaşın

    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            to="#"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            Alışverişlio Nedir?
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            to="#"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
            onClick={() => onTap(2)}
          >
            Markalar
          </Link>
        </li> */}
        <li className="nav-item">
          <Link
            to="#"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
            onClick={e => {
              e.preventDefault()

              if (window.location.pathname != "/") {
                navigate("/#kobi")
              } else {
                onTap(0)
              }
            }}
          >
            Üye İşyeri Ol
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="#"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
            onClick={e => {
              e.preventDefault()

              if (window.location.pathname != "/") {
                navigate("/#carsi")
              } else {
                onTap(1)
              }
            }}
          >
            Çarşı
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            to="#"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            Blog
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <Link
            to="#"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            SSS
          </Link>
        </li> */}
        <li className="nav-item">
          <Link
            to="/information-about-us"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            Hakkımızda
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/contact"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            Bize Ulaşın
          </Link>
        </li>
      </ul>
    )
  }

  const buildMultiSiteNavBarItems = () => {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            to="/"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            Anasayfa
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to=""
            activeClassName=""
            onClick={e => {
              e.preventDefault()
              window.open("https://onelink.to/ucrq8h")
            }}
            className="nav-link"
          >
            Uygulamayı İndir
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/register"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            Ücretsiz Kaydol
          </Link>
        </li>

        <li className="nav-item">
          <Link
            to="/contact"
            activeClassName="active"
            // onClick={e => e.preventDefault()}
            className="nav-link"
          >
            İletişim
          </Link>
        </li>
      </ul>
    )
  }

  const buildNavBarItems = () => {
    return buildAlisverislioNavBarItems()
  }
  return (
    <React.Fragment>
      <div id="navbar" className="navbar-area is-sticky">
        <div className="tarn-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" onClick={() => setCollapsed(true)}>
                <img src={logo} alt="logo" width={150} />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                {buildNavBarItems()}
                <div className="others-option d-flex align-items-center">
                  {/* <div className="option-item">
                    <form className="search-box">
                      <input
                        type="text"
                        className="input-search"
                        placeholder="Search for anything"
                      />
                      <button type="submit">
                        <i className="flaticon-loupe"></i>
                      </button>
                    </form>
                  </div> */}

                  {/* <div className="option-item">
                    {isMultiSite ? (
                      <></>
                    ) : (
                      <Link
                        to="/contact"
                        activeClassName="active"
                        onClick={() =>
                          window.open(
                            "https://panel.alisverislio.com/Account/Login"
                          )
                        }
                        className="default-btn"
                      >
                        <i className="flaticon-right"></i> Hesabım <span></span>
                      </Link>
                    )}
                  </div> */}
                  <Button
                    activeClassName="active"
                    onClick={() =>
                      window.open(
                        "https://panel.alisverislio.com/Account/Login"
                      )
                    }
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i> Hesabım <span></span>
                  </Button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Navbar
