import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"

import footerMap from "../../assets/images/footer-map.png"
import creditCardLogo from "../../assets/images/icons/kredikarti.png"
import alisverislioLogo from "../../assets/images/index/logo.png"
const Footer = props => {
  const currentYear = new Date().getFullYear()

  const [logo, setLogo] = useState()

  const socialMedias = () => {
    return (
      <ul className="social-link mt-3">
        <li>
          <Link
            to=""
            onClick={() => {
              window.open(
                "https://www.facebook.com/alisverislio",
                "_blank",
                "noreferrer"
              )
            }}
            className="d-block"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-facebook"></i>
          </Link>
        </li>
        <li>
          <Link
            to=""
            onClick={() => {
              window.open(
                "https://twitter.com/search?src=typd&q=alisverislio",
                "_blank",
                "noreferrer"
              )
            }}
            className="d-block"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-twitter"></i>
          </Link>
        </li>
        <li>
          <Link
            to=""
            onClick={() => {
              window.open(
                "https://www.instagram.com/alisverislio",
                "_blank",
                "noreferrer"
              )
            }}
            className="d-block"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-instagram"></i>
          </Link>
        </li>
        <li>
          <Link
            to=""
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UC73QXbCMX0eYe4PnYSkEVMg",
                "_blank",
                "noreferrer"
              )
            }}
            className="d-block"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-youtube"></i>
          </Link>
        </li>
        <li>
          <Link
            to=""
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/86456082",
                "_blank",
                "noreferrer"
              )
            }}
            className="d-block"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bx bxl-linkedin"></i>
          </Link>
        </li>
      </ul>
    )
  }

  useEffect(() => {
    setLogo(alisverislioLogo)
  }, [])
  return (
    <footer className="footer-area bg-color">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-6">
            <div className="single-footer-widget">
              <a href="/" className="logo">
                <img width={256} src={logo} alt="logo" />
              </a>
              <p>
                En bilindik markalarda, 55.000'den fazla noktada yaptığın
                alışverişlerinde tasarruf et. Benzersiz bu yeni nesil alışveriş
                modelinde, firmalar sana nakit iade yapsın.
              </p>
              {socialMedias()}
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Keşfet</h3>
              <ul className="footer-links-list">
                <li>
                  <Link to="/">Anasayfa</Link>
                </li>

                <li>
                  <Link to="/information-about-us">Hakkımızda</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Gizlilik Politikası</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">KVKK</Link>
                </li>
                <li>
                  <Link to="/contact">İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Kaynaklar</h3>

              <ul className="footer-links-list">
                <li>
                  <Link to="/">Uygulamayı indir</Link>
                </li>
                {/* <li>
                  <Link to="/register">Ücretsiz kaydol</Link>
                </li> */}
                <li>
                  <Link
                    to=""
                    // to="/become-investor#asd"
                    onClick={e => {
                      e.preventDefault()
                      navigate("/become-investor")
                    }}
                  >
                    Beni ara
                  </Link>
                </li>
                {/* <li>
                  <Link to="https://pay.alisverislio.com/">Paket Satın Al</Link>
                </li> */}
                <li>
                  <Link to="/contact">İletişim</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget">
              <h3>Adres</h3>

              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>
                  Kazlıçeşme Mah. 245.Sok No.5 Biruni Teknopark
                  <br /> Zeytinburnu/İstanbul
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <a href="tel:+902126512020">+90 (212) 651 20 20</a>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <a href="mailto:info@alisverislio.com">
                    info@alisverislio.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p style={{ fontSize: 13 }}>
                Copyright @{currentYear}{" "}
                <strong>Alışverişlio Elektronik Ticaret A.Ş.</strong> Tüm
                Hakları Saklıdır.
              </p>
            </div>
            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/privacy-policy">Gizlilik Politikası</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Şartlar ve Koşullar</Link>
                </li>
                <li>
                  <Link to="/refund-policy">İptal ve İade</Link>
                </li>
              </ul>
            </div>
            <div>
              <img src={creditCardLogo} />
            </div>
          </div>
        </div>
      </div>

      <div className="footer-map">
        <img src={footerMap} alt="footer-logo" />
      </div>
    </footer>
  )
}

export default Footer
